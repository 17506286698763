import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent implements OnInit{

  orw_unsubscribe: string = '';
  orw_unsubscribe_all: string = '';
  partner: string = '';
  message: string = 'You will no longer receive messages from us.';

  constructor(
    private route: ActivatedRoute
  ) {

  }


  ngOnInit(): void {
    console.log("confirmation screen");
    this.route.queryParams.subscribe(params => {
      this.orw_unsubscribe = params['orw_unsubscribe'];
      this.orw_unsubscribe_all = params['orw_unsubscribe_all'];
      this.partner = params['partner'];
      console.log(this.orw_unsubscribe + "," + this.orw_unsubscribe_all + "," + this.partner);
      if (this.orw_unsubscribe_all === "true") {
        this.message = "You will no longer receive messages from us.";
      } else if (this.orw_unsubscribe === "true"){
        this.message = "You will no longer receive renewal messages from " + this.partner + " Roadside Assistance.";
      }

    });
  }

}
