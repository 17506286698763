import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UnsubscribeRequest } from '../../interface/unsubscribe-request';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SaveService {

  saveUrl = '/forms';

  private unsubscribeRequest: UnsubscribeRequest | undefined;

  constructor(private http: HttpClient) {

  }


  goSave(orw_unsubscribe: any, orw_unsubscribe_all: any, id: any) {
    console.log("save form...");
    this.unsubscribeRequest = {
      client: environment.APP_CLIENTID,
      orw_unsubscribe: orw_unsubscribe,
      orw_unsubscribe_all: orw_unsubscribe_all
    };

    return this.http.post(this.saveUrl + "/" + id, JSON.stringify(this.unsubscribeRequest))
      .pipe(
        map((data: any) => {
          return data || {}
        }),
        catchError((error: any) => {
          console.log('error caught in save data service');
          console.error(error);

          //Handle the error here

          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
              console.error("Error Event");
            } else {
              console.log(`error status : ${error.status} ${error.statusText}`);
              return throwError(error);
            }
          } else {
            console.error("unsubscribe service - something else happened");
            console.error(error.message);
          }
          return throwError(error);    //Rethrow it back to component
        })
      );

  }




}
