import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { FormFieldsResponse } from '../../interface/field-response';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RetrieveService {

  constructor(
    private http: HttpClient
  ) { }

  goRetrieve(formId: string){
      return this.http.get<FormFieldsResponse>('/forms/'+formId+"?client="+environment.APP_CLIENTID)
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error: any) => {
          console.log('error caught in retrieve form service');
          console.error(error);

          //Handle the error here

          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
              console.error("Error Event");
            } else {
              console.log(`error status : ${error.status} ${error.statusText}`);
              return throwError(error);
            }
          } else {
            console.error("unsubscribe service - something else happened");
            console.error(error.message);
          }
          return throwError(error);    //Rethrow it back to component
        })
      );
  }
}
