export const environment = {
    production: false,
    brand: 'awpusuat',
    APP_CLIENTID: 'awpusuat',
    APP_TEMPLATEID: 'awpusuat_templates',
    APP_ORIGINATINGAPP: 'awpusuat',    
    APP_MESSAGE_BAD_REQUEST    : 'Please retry the link you initially received.',
    APP_MESSAGE_FORM_EXPIRED   : 'This item has expired or is no longer available.',
    APP_MESSAGE_NOT_FOUND      : 'Please retry the link you initially received.',
    APP_MESSAGE_FORM_LOCKUP    : 'You have exceeded all attempts for today. Your access has been temporarily disabled. Please retry the link you initially received in 24hrs.',
    APP_MESSAGE_FORBIDDEN      : 'Please retry the link you initially received.',
    APP_MESSAGE_NO_ID          : 'Please retry the link you initially received without modifcation.',
    APP_MESSAGE_COOKIE_EXPIRED : 'Your session has timed out. Please retry the link you initially received without modifcation.',
    APP_MESSAGE_DEFAULT        : 'Please retry the link you initially received at a later time.'
};
