<div class="row main">
    <div class="card w-50 mx-auto">
      <div class="card-body">
        <h5 class="card-title">Unsubscribe</h5>

        <div *ngIf="isLoading" class="d-flex justify-content-center">
          <div class="spinner-border text-danger" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div class="card-text client-radio-button">

          <form [formGroup]="form" (ngSubmit)="openModal(confirmationModal)">

            <div class="form-check">
              <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="orw_unsubscribe" id="orw_unsubscribe"
                  (change)="onCheckboxChange($event)"
              />
              <label class="form-check-label" for="orw_unsubscribe"> Unsubscribe me from {{partner}} Roadside renewals</label>
            </div>

            <div class="form-check">
              <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="orw_unsubscribe_all" id="orw_unsubscribe_all"
                  (change)="onCheckboxChange($event)"
              />
              <label class="form-check-label" for="orw_unsubscribe_all"> Unsubscribe me from all electronic mailing lists as I no longer want to receive messages from AWP Australia Pty Ltd </label>
            </div>   

            <section class="alerts-section">
              <div *ngFor="let alert of alerts">
                <alert [type]="alert.type" [dismissible]="true"
                  ><span [innerHtml]="alert.msg"></span
                ></alert>
              </div>
            </section>              
            
            <button class="btn btn-primary client-button-primary" 
              [disabled]="!isValidForm">Unsubscribe</button>            

          </form>

        </div>

      </div>
    </div>
  </div>
  
  <!-- Modal -->
  
  <ng-template #confirmationModal>
    <div class="modal-xl">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          Confirm unsubscription?
        </h4>      
        <button
          type="button"
          class="btn-close close pull-right"
          aria-label="Close"
          (click)="closeModal()">
        >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"             
          (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-primary client-button-primary"
          (click)="goUnsubscribe()">Unsubscribe</button>
      </div>
    </div>
    <!-- end of modal-header -->
  </ng-template>
  
  