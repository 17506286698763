import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';

const routes: Routes = [
  { path: '', component: UnsubscribeComponent },
  { path: 'unsubscribe', component: UnsubscribeComponent },
  { path: 'confirmation', component: ConfirmationComponent },
  { path: '**', component: UnsubscribeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
