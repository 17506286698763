import { Component, OnInit, TemplateRef, SecurityContext } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SaveService } from '../service/save/save.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { RetrieveService } from '../service/retrieve/retrieve.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrl: './unsubscribe.component.scss',
  providers: [SaveService]
})
export class UnsubscribeComponent implements OnInit {
  
  modalRef?: BsModalRef | null;
  form!: FormGroup;

  id: any;
  partner: any;

  alerts: any = [];
  error_message!: string;
  error: any;

  isLoading: boolean = true;
  isValidForm: boolean = false;

  constructor(
    private modalService: BsModalService,
    private saveService: SaveService,
    private retrieveService: RetrieveService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
  ) {

  }

  ngOnInit(): void {
    const urlPath = this.route.snapshot.url.map(segment => segment.path).join('/');
    console.log(urlPath); 
    this.id = urlPath;

    this.retrieveService.goRetrieve(this.id)
      .subscribe(

        (data: any) => {
          console.log("Retrieved successfully");
          this.partner = data.or_partner;
          this.isLoading = false;
        },
        (err: any) => {
          this.partner = "Partner ID:" + this.id;
          this.isLoading = false;
          console.log("Error in retrieving form id");
  
          this.error = this.renderError(err?.code);
          this.error_message = this.error?.error_message;
          this.pushAlerts("danger", this.error_message);
        }
      );

    this.form = this.formBuilder.group({
      orw_unsubscribe: [false],
      orw_unsubscribe_all: [false]
    });


  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  goUnsubscribe(): void {
    // do the processing
    this.closeModal();
    let orw_unsubscribe = this.form.get('orw_unsubscribe')?.value;
    let orw_unsubscribe_all = this.form.get('orw_unsubscribe_all')?.value;
    this.saveService.goSave(
      orw_unsubscribe,
      orw_unsubscribe_all,
      this.id
    ).subscribe(
      (data: any) => {
        console.log("Unsubscribed successfully");
        this.router.navigate(["confirmation"],
          { queryParams: { 
            orw_unsubscribe: orw_unsubscribe,
            orw_unsubscribe_all:  orw_unsubscribe_all,
            partner: this.partner
          } }
        );
      },
      (err: any) => {
        console.log("Error in unsubscribing");
        this.error = this.renderError(err?.code);
        this.error_message = this.error?.error_message;
        this.pushAlerts("danger", this.error_message);
      }
    )

  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

  onCheckboxChange(event: any) {
    console.log('Checkbox checked:', event.target.checked);
    let orw_unsubscribe = this.form.get('orw_unsubscribe')?.value;
    let orw_unsubscribe_all = this.form.get('orw_unsubscribe_all')?.value;
    if (!orw_unsubscribe && !orw_unsubscribe_all) {
      this.isValidForm = false;
    } else {
      this.isValidForm = true;
    }
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.hide();
    this.modalRef = null;
  }

  pushAlerts(type: string, message: string): void {
    this.alerts.push({
      type: type,
      msg: this.sanitizer.sanitize(SecurityContext.HTML, message),
    });
  }

  private renderError(errorStatus: any): any {
    let errorContent: {error_type: string,error_message: string,error_details: string};
    switch (errorStatus) {
        case 'BAD_REQUEST':
            errorContent = { "error_type": "Missing or invalid values", "error_message": environment.APP_MESSAGE_BAD_REQUEST, "error_details": errorStatus }
            break;
        case 'FORM_EXPIRED':
            errorContent = { "error_type": "Link no longer valid", "error_message": environment.APP_MESSAGE_FORM_EXPIRED, "error_details": errorStatus }
            break;
        case 'NOT_FOUND':
            errorContent = { "error_type": "Record not found", "error_message": environment.APP_MESSAGE_NOT_FOUND, "error_details": errorStatus }
            break;
        case 'FORM_LOCKUP':
            errorContent = { "error_type": "Too many failed attempts", "error_message": environment.APP_MESSAGE_FORM_LOCKUP, "error_details": errorStatus }
            break;
        case 'FORBIDDEN':
        case 'SERVICE_UNAVAILABLE':
            errorContent = { "error_type": "Item unavailable", "error_message": environment.APP_MESSAGE_FORBIDDEN, "error_details": errorStatus }
            break;
        case 'NO_ID':
            errorContent = { "error_type": "Invalid link", "error_message": environment.APP_MESSAGE_NO_ID, "error_details": "No ID supplied" }
            break;
        case 'SESSION_DOES_NOT_EXIST':
        case 'COOKIE_EXPIRED':
            errorContent = { "error_type": "Session expired", "error_message": environment.APP_MESSAGE_COOKIE_EXPIRED, "error_details": "Session expired" }
            break;
        case 'PAGE_NOT_FOUND':
            errorContent = { "error_type": "Page not found", "error_message": environment.APP_MESSAGE_NOT_FOUND, "error_details": "Page not found" }
            break;
        default:
            errorContent = { "error_type": "Something went wrong. Please try again later.", "error_message": environment.APP_MESSAGE_DEFAULT, "error_details": errorStatus }
    }
    return errorContent;
  }  
}
